import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable()
export class RoleService {

    getEventRoute = '/api/role/';

    constructor(private dataService: DataService) {
    };

    get(refresh: Boolean) {
        return this.dataService.getData('/api/roles', refresh);
    }

    getById(id: number) {
        return this.dataService.getRecord('/api/role/' + id);
    }

    getMenu(refresh: Boolean) {
        return this.dataService.getData('/api/user/menu', refresh);
    }

    save(role) {
        return this.dataService.post('/api/role', role).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }
    saveActiveStatus(activeState: any) {
        return this.dataService.post('/api/role/activate', activeState);
    }

    getgender() {
        return this.dataService.getData('/api/lookup/gendertype', true)
    }

    getroletype() {
        return this.dataService.getData('/api/user/role', true)
    }

    getcookingcenter(id: any, refresh: Boolean,districtId:any) {
        return this.dataService.getRecord('/api/lookup/cc/' + id + '/' + refresh+'/'+districtId);
    }
    
    getschoolnamelist(roleId: any, langType: any, refresh: Boolean,districtId:any) {
        return this.dataService.getRecord('/api/lookup/school/' + roleId + '/' + langType + '/' + refresh+'/'+districtId);
    }

    getUserById(id:any)
    {
        return this.dataService.getData('/api/user/'+id,true);
    }

    saveuser(data:any)
    {
        return this.dataService.post("/api/user",data);
    }


    getHolidayGrid()
    {
        return this.dataService.getData('/api/holiday',true);
    }

    getHolidayById(id:any)
    {
        return this.dataService.getData('/api/holiday/'+id,true);
    }

    saveHoliday(data:any)
    {
        return this.dataService.post('/api/holiday',data);
    }

    lookUpDistrict()
    {
        return this.dataService.getData('/api/lookup/district/'+1,true);
    }

    // getCalendarList()
    // {
    //     return this.dataService.getData('/api/holiday/calender',true)
    // }
    getcalbyDistrict(districtId){
        console.log("districtId",districtId)
        return this.dataService.getData('/api/holiday/calender/'+districtId,true)
    }

    deleteHoliday(id)
    {
        return this.dataService.post('/api/holiday/delete',id)
    }


}
