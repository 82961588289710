import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable()
export class DistrictService {

    getEventRoute = '/api/district/';

    constructor(private dataService: DataService) {
    };

    getLookUp(refresh: boolean) {
        return this.dataService.getData('/api/lookup/district', refresh);
    }

    getLookUpDistrict(langType: any, refresh: boolean, areaType: number) {
        return this.dataService.getData('/api/lookup/district/' + areaType + '/' + langType, refresh);
    }

    getLookUpAll(refresh: boolean) {
        return this.dataService.getData('/api/lookup/alldistrict', refresh);
    }

    // getLookUpDelta(refresh: boolean) {
    //     return this.dataService.getData('/api/lookup/blocktype', refresh);
    // }

    get(refresh: boolean) {
        return this.dataService.getData('/api/district', refresh);
    }

    getById(id: number, refresh: boolean) {
        return this.dataService.getData('/api/district/' + id, refresh);
    }

    save(result: any) {
        return this.dataService.post('/api/district', result).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    delete(id: number) {
        return this.dataService.delete('/api/district/' + id).map(response => {
            this.dataService.clearCache();
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }


    getFoodGridDetails()
    {
        return this.dataService.getData('/api/fooditem/foodItemList',true)
    }

    getDayType()
    {
        return this.dataService.getData('/api/lookup/daytype',true)

    }

    getTiming()
    {
        return this.dataService.getData('/api/lookup/foodtimingtype',true)

    }

    saveFoodMenu(data)
    {
        return this.dataService.post('/api/fooditem',data)
    }

    getFoodMenuById(id)
    {
        return this.dataService.getData('/api/fooditem/foodItem/'+id,true)
    }

    getCommitteeDetails(districtId:number,municipalityId:number,zoneId:any)
    {
        return this.dataService.getData('/api/committeemember/'+districtId+'/'+municipalityId+'/'+zoneId,true)
    }

    getCommiteeById(id:any)
    {
        return this.dataService.getData('/api/committeemember/'+id,true)
    }

    getUserDetails()
    {
        return this.dataService.getData('/api/user',true)
    }

    activate(data)
    {
        return this.dataService.post('/api/user/update',data)
    }

    activatee(data)
    {
        return this.dataService.getData('/api/user/update/'+data.id+'/'+data.statusType,true)
    }
    
}

